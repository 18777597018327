/*
  This files is used to pre-build data during the build process.
  Avoid adding client-side code here, as it can break the build process.
*/
import { ARBITRUM, AVALANCHE, AVALANCHE_FUJI } from "./chains";

export type MarketConfig = {
  marketTokenAddress: string;
  indexTokenAddress: string;
  longTokenAddress: string;
  shortTokenAddress: string;
};

/*
  ATTENTION
  When adding new markets, please add them also to the end of the list in ./src/configs/static/sortedMarkets.ts
*/
export const MARKETS: Record<string, Record<string, MarketConfig>> = {
  [ARBITRUM]: {
    // BTC/USD [WBTC.e-USDC]
    "0x47c031236e19d024b42f8AE6780E44A573170703": {
      marketTokenAddress: "0x47c031236e19d024b42f8AE6780E44A573170703",
      indexTokenAddress: "0x47904963fc8b2340414262125aF798B9655E58Cd",
      longTokenAddress: "0x2f2a2543B76A4166549F7aaB2e75Bef0aefC5B0f",
      shortTokenAddress: "0xaf88d065e77c8cC2239327C5EDb3A432268e5831",
    },
    // ETH/USD [WETH-USDC]
    "0x70d95587d40A2caf56bd97485aB3Eec10Bee6336": {
      marketTokenAddress: "0x70d95587d40A2caf56bd97485aB3Eec10Bee6336",
      indexTokenAddress: "0x82aF49447D8a07e3bd95BD0d56f35241523fBab1",
      longTokenAddress: "0x82aF49447D8a07e3bd95BD0d56f35241523fBab1",
      shortTokenAddress: "0xaf88d065e77c8cC2239327C5EDb3A432268e5831",
    },
    // DOGE/USD [WETH-USDC]
    "0x6853EA96FF216fAb11D2d930CE3C508556A4bdc4": {
      marketTokenAddress: "0x6853EA96FF216fAb11D2d930CE3C508556A4bdc4",
      indexTokenAddress: "0xC4da4c24fd591125c3F47b340b6f4f76111883d8",
      longTokenAddress: "0x82aF49447D8a07e3bd95BD0d56f35241523fBab1",
      shortTokenAddress: "0xaf88d065e77c8cC2239327C5EDb3A432268e5831",
    },
    // SOL/USD [SOL-USDC]
    "0x09400D9DB990D5ed3f35D7be61DfAEB900Af03C9": {
      marketTokenAddress: "0x09400D9DB990D5ed3f35D7be61DfAEB900Af03C9",
      indexTokenAddress: "0x2bcC6D6CdBbDC0a4071e48bb3B969b06B3330c07",
      longTokenAddress: "0x2bcC6D6CdBbDC0a4071e48bb3B969b06B3330c07",
      shortTokenAddress: "0xaf88d065e77c8cC2239327C5EDb3A432268e5831",
    },
    // LTC/USD [WETH-USDC]
    "0xD9535bB5f58A1a75032416F2dFe7880C30575a41": {
      marketTokenAddress: "0xD9535bB5f58A1a75032416F2dFe7880C30575a41",
      indexTokenAddress: "0xB46A094Bc4B0adBD801E14b9DB95e05E28962764",
      longTokenAddress: "0x82aF49447D8a07e3bd95BD0d56f35241523fBab1",
      shortTokenAddress: "0xaf88d065e77c8cC2239327C5EDb3A432268e5831",
    },
    // UNI/USD [UNI-USDC]
    "0xc7Abb2C5f3BF3CEB389dF0Eecd6120D451170B50": {
      marketTokenAddress: "0xc7Abb2C5f3BF3CEB389dF0Eecd6120D451170B50",
      indexTokenAddress: "0xFa7F8980b0f1E64A2062791cc3b0871572f1F7f0",
      longTokenAddress: "0xFa7F8980b0f1E64A2062791cc3b0871572f1F7f0",
      shortTokenAddress: "0xaf88d065e77c8cC2239327C5EDb3A432268e5831",
    },
    // LINK/USD [LINK-USDC]
    "0x7f1fa204bb700853D36994DA19F830b6Ad18455C": {
      marketTokenAddress: "0x7f1fa204bb700853D36994DA19F830b6Ad18455C",
      indexTokenAddress: "0xf97f4df75117a78c1A5a0DBb814Af92458539FB4",
      longTokenAddress: "0xf97f4df75117a78c1A5a0DBb814Af92458539FB4",
      shortTokenAddress: "0xaf88d065e77c8cC2239327C5EDb3A432268e5831",
    },
    // ARB/USD [ARB-USDC]
    "0xC25cEf6061Cf5dE5eb761b50E4743c1F5D7E5407": {
      marketTokenAddress: "0xC25cEf6061Cf5dE5eb761b50E4743c1F5D7E5407",
      indexTokenAddress: "0x912CE59144191C1204E64559FE8253a0e49E6548",
      longTokenAddress: "0x912CE59144191C1204E64559FE8253a0e49E6548",
      shortTokenAddress: "0xaf88d065e77c8cC2239327C5EDb3A432268e5831",
    },
    // SWAP-ONLY [USDC-USDC.e]
    "0x9C2433dFD71096C435Be9465220BB2B189375eA7": {
      marketTokenAddress: "0x9C2433dFD71096C435Be9465220BB2B189375eA7",
      indexTokenAddress: "0x0000000000000000000000000000000000000000",
      longTokenAddress: "0xaf88d065e77c8cC2239327C5EDb3A432268e5831",
      shortTokenAddress: "0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8",
    },
    // SWAP-ONLY [USDC-USDT]
    "0xB686BcB112660343E6d15BDb65297e110C8311c4": {
      marketTokenAddress: "0xB686BcB112660343E6d15BDb65297e110C8311c4",
      indexTokenAddress: "0x0000000000000000000000000000000000000000",
      longTokenAddress: "0xaf88d065e77c8cC2239327C5EDb3A432268e5831",
      shortTokenAddress: "0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9",
    },
    // SWAP-ONLY [USDC-DAI]
    "0xe2fEDb9e6139a182B98e7C2688ccFa3e9A53c665": {
      marketTokenAddress: "0xe2fEDb9e6139a182B98e7C2688ccFa3e9A53c665",
      indexTokenAddress: "0x0000000000000000000000000000000000000000",
      longTokenAddress: "0xaf88d065e77c8cC2239327C5EDb3A432268e5831",
      shortTokenAddress: "0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1",
    },
    // XRP/USD [WETH-USDC]
    "0x0CCB4fAa6f1F1B30911619f1184082aB4E25813c": {
      marketTokenAddress: "0x0CCB4fAa6f1F1B30911619f1184082aB4E25813c",
      indexTokenAddress: "0xc14e065b0067dE91534e032868f5Ac6ecf2c6868",
      longTokenAddress: "0x82aF49447D8a07e3bd95BD0d56f35241523fBab1",
      shortTokenAddress: "0xaf88d065e77c8cC2239327C5EDb3A432268e5831",
    },
    // BNB/USD [BNB-USDC]
    "0x2d340912Aa47e33c90Efb078e69E70EFe2B34b9B": {
      marketTokenAddress: "0x2d340912Aa47e33c90Efb078e69E70EFe2B34b9B",
      indexTokenAddress: "0xa9004A5421372E1D83fB1f85b0fc986c912f91f3",
      longTokenAddress: "0xa9004A5421372E1D83fB1f85b0fc986c912f91f3",
      shortTokenAddress: "0xaf88d065e77c8cC2239327C5EDb3A432268e5831",
    },
    // AAVE [AAVE-USDC]
    "0x1CbBa6346F110c8A5ea739ef2d1eb182990e4EB2": {
      marketTokenAddress: "0x1CbBa6346F110c8A5ea739ef2d1eb182990e4EB2",
      indexTokenAddress: "0xba5DdD1f9d7F570dc94a51479a000E3BCE967196",
      longTokenAddress: "0xba5DdD1f9d7F570dc94a51479a000E3BCE967196",
      shortTokenAddress: "0xaf88d065e77c8cC2239327C5EDb3A432268e5831",
    },
    // ATOM [WETH-USDC]
    "0x248C35760068cE009a13076D573ed3497A47bCD4": {
      marketTokenAddress: "0x248C35760068cE009a13076D573ed3497A47bCD4",
      indexTokenAddress: "0x7D7F1765aCbaF847b9A1f7137FE8Ed4931FbfEbA",
      longTokenAddress: "0x82aF49447D8a07e3bd95BD0d56f35241523fBab1",
      shortTokenAddress: "0xaf88d065e77c8cC2239327C5EDb3A432268e5831",
    },
    // NEAR [WETH-USDC]
    "0x63Dc80EE90F26363B3FCD609007CC9e14c8991BE": {
      marketTokenAddress: "0x63Dc80EE90F26363B3FCD609007CC9e14c8991BE",
      indexTokenAddress: "0x1FF7F3EFBb9481Cbd7db4F932cBCD4467144237C",
      longTokenAddress: "0x82aF49447D8a07e3bd95BD0d56f35241523fBab1",
      shortTokenAddress: "0xaf88d065e77c8cC2239327C5EDb3A432268e5831",
    },
    // AVAX [WAVAX-USDC]
    "0x7BbBf946883a5701350007320F525c5379B8178A": {
      marketTokenAddress: "0x7BbBf946883a5701350007320F525c5379B8178A",
      indexTokenAddress: "0x565609fAF65B92F7be02468acF86f8979423e514",
      longTokenAddress: "0x565609fAF65B92F7be02468acF86f8979423e514",
      shortTokenAddress: "0xaf88d065e77c8cC2239327C5EDb3A432268e5831",
    },
    // OP [OP-USDC]
    "0x4fDd333FF9cA409df583f306B6F5a7fFdE790739": {
      marketTokenAddress: "0x4fDd333FF9cA409df583f306B6F5a7fFdE790739",
      indexTokenAddress: "0xaC800FD6159c2a2CB8fC31EF74621eB430287a5A",
      longTokenAddress: "0xaC800FD6159c2a2CB8fC31EF74621eB430287a5A",
      shortTokenAddress: "0xaf88d065e77c8cC2239327C5EDb3A432268e5831",
    },
    // BTC/USD [WBTC.e-WBTC.e]
    "0x7C11F78Ce78768518D743E81Fdfa2F860C6b9A77": {
      marketTokenAddress: "0x7C11F78Ce78768518D743E81Fdfa2F860C6b9A77",
      indexTokenAddress: "0x47904963fc8b2340414262125aF798B9655E58Cd",
      longTokenAddress: "0x2f2a2543B76A4166549F7aaB2e75Bef0aefC5B0f",
      shortTokenAddress: "0x2f2a2543B76A4166549F7aaB2e75Bef0aefC5B0f",
    },
    // ETH/USD [WETH-WETH]
    "0x450bb6774Dd8a756274E0ab4107953259d2ac541": {
      marketTokenAddress: "0x450bb6774Dd8a756274E0ab4107953259d2ac541",
      indexTokenAddress: "0x82aF49447D8a07e3bd95BD0d56f35241523fBab1",
      longTokenAddress: "0x82aF49447D8a07e3bd95BD0d56f35241523fBab1",
      shortTokenAddress: "0x82aF49447D8a07e3bd95BD0d56f35241523fBab1",
    },
    // GMX/USD [GMX-USDC]
    "0x55391D178Ce46e7AC8eaAEa50A72D1A5a8A622Da": {
      marketTokenAddress: "0x55391D178Ce46e7AC8eaAEa50A72D1A5a8A622Da",
      indexTokenAddress: "0xfc5A1A6EB076a2C7aD06eD22C90d7E710E35ad0a",
      longTokenAddress: "0xfc5A1A6EB076a2C7aD06eD22C90d7E710E35ad0a",
      shortTokenAddress: "0xaf88d065e77c8cC2239327C5EDb3A432268e5831",
    },
    // PEPE [PEPE-USDC]
    "0x2b477989A149B17073D9C9C82eC9cB03591e20c6": {
      marketTokenAddress: "0x2b477989A149B17073D9C9C82eC9cB03591e20c6",
      indexTokenAddress: "0x25d887Ce7a35172C62FeBFD67a1856F20FaEbB00",
      longTokenAddress: "0x25d887Ce7a35172C62FeBFD67a1856F20FaEbB00",
      shortTokenAddress: "0xaf88d065e77c8cC2239327C5EDb3A432268e5831",
    },
    // WIF [WIF-USDC]
    "0x0418643F94Ef14917f1345cE5C460C37dE463ef7": {
      marketTokenAddress: "0x0418643F94Ef14917f1345cE5C460C37dE463ef7",
      indexTokenAddress: "0xA1b91fe9FD52141Ff8cac388Ce3F10BFDc1dE79d",
      longTokenAddress: "0xA1b91fe9FD52141Ff8cac388Ce3F10BFDc1dE79d",
      shortTokenAddress: "0xaf88d065e77c8cC2239327C5EDb3A432268e5831",
    },
    // ETH/USD [wstETH-USDe]
    "0x0Cf1fb4d1FF67A3D8Ca92c9d6643F8F9be8e03E5": {
      marketTokenAddress: "0x0Cf1fb4d1FF67A3D8Ca92c9d6643F8F9be8e03E5",
      indexTokenAddress: "0x82aF49447D8a07e3bd95BD0d56f35241523fBab1",
      longTokenAddress: "0x5979D7b546E38E414F7E9822514be443A4800529",
      shortTokenAddress: "0x5d3a1Ff2b6BAb83b63cd9AD0787074081a52ef34",
    },
    // SWAP-ONLY [wstETH-WETH]
    "0xb56E5E2eB50cf5383342914b0C85Fe62DbD861C8": {
      marketTokenAddress: "0xb56E5E2eB50cf5383342914b0C85Fe62DbD861C8",
      indexTokenAddress: "0x0000000000000000000000000000000000000000",
      longTokenAddress: "0x5979D7b546E38E414F7E9822514be443A4800529",
      shortTokenAddress: "0x82aF49447D8a07e3bd95BD0d56f35241523fBab1",
    },
    // SWAP-ONLY [USDe-USDC]
    "0x45aD16Aaa28fb66Ef74d5ca0Ab9751F2817c81a4": {
      marketTokenAddress: "0x45aD16Aaa28fb66Ef74d5ca0Ab9751F2817c81a4",
      indexTokenAddress: "0x0000000000000000000000000000000000000000",
      longTokenAddress: "0x5d3a1Ff2b6BAb83b63cd9AD0787074081a52ef34",
      shortTokenAddress: "0xaf88d065e77c8cC2239327C5EDb3A432268e5831",
    },
    // SHIB/USD [WETH-USDC]
    "0xB62369752D8Ad08392572db6d0cc872127888beD": {
      marketTokenAddress: "0xB62369752D8Ad08392572db6d0cc872127888beD",
      indexTokenAddress: "0x3E57D02f9d196873e55727382974b02EdebE6bfd",
      longTokenAddress: "0x82aF49447D8a07e3bd95BD0d56f35241523fBab1",
      shortTokenAddress: "0xaf88d065e77c8cC2239327C5EDb3A432268e5831",
    },
    // STX/USD [wBTC-USDC]
    "0xD9377d9B9a2327C7778867203deeA73AB8a68b6B": {
      marketTokenAddress: "0xD9377d9B9a2327C7778867203deeA73AB8a68b6B",
      indexTokenAddress: "0xBaf07cF91D413C0aCB2b7444B9Bf13b4e03c9D71",
      longTokenAddress: "0x2f2a2543B76A4166549F7aaB2e75Bef0aefC5B0f",
      shortTokenAddress: "0xaf88d065e77c8cC2239327C5EDb3A432268e5831",
    },
    // ORDI/USD [wBTC-USDC]
    "0x93385F7C646A3048051914BDFaC25F4d620aeDF1": {
      marketTokenAddress: "0x93385F7C646A3048051914BDFaC25F4d620aeDF1",
      indexTokenAddress: "0x1E15d08f3CA46853B692EE28AE9C7a0b88a9c994",
      longTokenAddress: "0x2f2a2543B76A4166549F7aaB2e75Bef0aefC5B0f",
      shortTokenAddress: "0xaf88d065e77c8cC2239327C5EDb3A432268e5831",
    },
    // BTC/USD [tBTC]
    "0xd62068697bCc92AF253225676D618B0C9f17C663": {
      marketTokenAddress: "0xd62068697bCc92AF253225676D618B0C9f17C663",
      indexTokenAddress: "0x47904963fc8b2340414262125aF798B9655E58Cd",
      longTokenAddress: "0x6c84a8f1c29108F47a79964b5Fe888D4f4D0dE40",
      shortTokenAddress: "0x6c84a8f1c29108F47a79964b5Fe888D4f4D0dE40",
    },
    //  EIGEN/USD [WETH-USDC]
    "0xD4b737892baB8446Ea1e8Bb901db092fb1EC1791": {
      marketTokenAddress: "0xD4b737892baB8446Ea1e8Bb901db092fb1EC1791",
      indexTokenAddress: "0x606C3e5075e5555e79Aa15F1E9FACB776F96C248",
      longTokenAddress: "0x82aF49447D8a07e3bd95BD0d56f35241523fBab1",
      shortTokenAddress: "0xaf88d065e77c8cC2239327C5EDb3A432268e5831",
    },
    //  SATS/USD [WBTC-USDC]
    "0x8ea4Fb801493DaD8724F90Fb2e279534fa591366": {
      marketTokenAddress: "0x8ea4Fb801493DaD8724F90Fb2e279534fa591366",
      indexTokenAddress: "0x2cD2eB61D17b78239Fcd19aafF72981B5D5eF319",
      longTokenAddress: "0x2f2a2543B76A4166549F7aaB2e75Bef0aefC5B0f",
      shortTokenAddress: "0xaf88d065e77c8cC2239327C5EDb3A432268e5831",
    },
    // POL/USD [ETH-USDC]
    "0xD0a1AFDDE31Eb51e8b53bdCE989EB8C2404828a4": {
      marketTokenAddress: "0xD0a1AFDDE31Eb51e8b53bdCE989EB8C2404828a4",
      indexTokenAddress: "0x9c74772b713a1B032aEB173E28683D937E51921c",
      longTokenAddress: "0x82aF49447D8a07e3bd95BD0d56f35241523fBab1",
      shortTokenAddress: "0xaf88d065e77c8cC2239327C5EDb3A432268e5831",
    },
    // AAVE/USD [ETH-USDC]
    "0x77B2eC357b56c7d05a87971dB0188DBb0C7836a5": {
      marketTokenAddress: "0x77B2eC357b56c7d05a87971dB0188DBb0C7836a5",
      indexTokenAddress: "0xba5DdD1f9d7F570dc94a51479a000E3BCE967196",
      longTokenAddress: "0x82aF49447D8a07e3bd95BD0d56f35241523fBab1",
      shortTokenAddress: "0xaf88d065e77c8cC2239327C5EDb3A432268e5831",
    },
    // PEPE/USD [ETH-USDC]
    "0x0Bb2a83F995E1E1eae9D7fDCE68Ab1ac55b2cc85": {
      marketTokenAddress: "0x0Bb2a83F995E1E1eae9D7fDCE68Ab1ac55b2cc85",
      indexTokenAddress: "0x25d887Ce7a35172C62FeBFD67a1856F20FaEbB00",
      longTokenAddress: "0x82aF49447D8a07e3bd95BD0d56f35241523fBab1",
      shortTokenAddress: "0xaf88d065e77c8cC2239327C5EDb3A432268e5831",
    },
    // UNI/USD [ETH-USDC]
    "0xD8471b9Ea126272E6d32B5e4782Ed76DB7E554a4": {
      marketTokenAddress: "0xD8471b9Ea126272E6d32B5e4782Ed76DB7E554a4",
      indexTokenAddress: "0xFa7F8980b0f1E64A2062791cc3b0871572f1F7f0",
      longTokenAddress: "0x82aF49447D8a07e3bd95BD0d56f35241523fBab1",
      shortTokenAddress: "0xaf88d065e77c8cC2239327C5EDb3A432268e5831",
    },
    // APE/USD [APE-USDC]
    "0xdAB21c4d1F569486334C93685Da2b3F9b0A078e8": {
      marketTokenAddress: "0xdAB21c4d1F569486334C93685Da2b3F9b0A078e8",
      indexTokenAddress: "0x7f9FBf9bDd3F4105C478b996B648FE6e828a1e98",
      longTokenAddress: "0x7f9FBf9bDd3F4105C478b996B648FE6e828a1e98",
      shortTokenAddress: "0xaf88d065e77c8cC2239327C5EDb3A432268e5831",
    },
    // SUI/USD [WETH-USDC]
    "0x6Ecf2133E2C9751cAAdCb6958b9654baE198a797": {
      marketTokenAddress: "0x6Ecf2133E2C9751cAAdCb6958b9654baE198a797",
      indexTokenAddress: "0x197aa2DE1313c7AD50184234490E12409B2a1f95",
      longTokenAddress: "0x82aF49447D8a07e3bd95BD0d56f35241523fBab1",
      shortTokenAddress: "0xaf88d065e77c8cC2239327C5EDb3A432268e5831",
    },
    // SEI/USD [WETH-USDC]
    "0xB489711B1cB86afDA48924730084e23310EB4883": {
      marketTokenAddress: "0xB489711B1cB86afDA48924730084e23310EB4883",
      indexTokenAddress: "0x55e85A147a1029b985384822c0B2262dF8023452",
      longTokenAddress: "0x82aF49447D8a07e3bd95BD0d56f35241523fBab1",
      shortTokenAddress: "0xaf88d065e77c8cC2239327C5EDb3A432268e5831",
    },
    // APT/USD [WETH-USDC]
    "0x66A69c8eb98A7efE22A22611d1967dfec786a708": {
      marketTokenAddress: "0x66A69c8eb98A7efE22A22611d1967dfec786a708",
      indexTokenAddress: "0x3f8f0dCE4dCE4d0D1d0871941e79CDA82cA50d0B",
      longTokenAddress: "0x82aF49447D8a07e3bd95BD0d56f35241523fBab1",
      shortTokenAddress: "0xaf88d065e77c8cC2239327C5EDb3A432268e5831",
    },
    // TIA/USD [WETH-USDC]
    "0xBeB1f4EBC9af627Ca1E5a75981CE1AE97eFeDA22": {
      marketTokenAddress: "0xBeB1f4EBC9af627Ca1E5a75981CE1AE97eFeDA22",
      indexTokenAddress: "0x38676f62d166f5CE7De8433F51c6B3D6D9d66C19",
      longTokenAddress: "0x82aF49447D8a07e3bd95BD0d56f35241523fBab1",
      shortTokenAddress: "0xaf88d065e77c8cC2239327C5EDb3A432268e5831",
    },
    // TRX/USD [WETH-USDC]
    "0x3680D7bFE9260D3c5DE81AEB2194c119a59A99D1": {
      marketTokenAddress: "0x3680D7bFE9260D3c5DE81AEB2194c119a59A99D1",
      indexTokenAddress: "0xb06aa7E4af937C130dDade66f6ed7642716fe07A",
      longTokenAddress: "0x82aF49447D8a07e3bd95BD0d56f35241523fBab1",
      shortTokenAddress: "0xaf88d065e77c8cC2239327C5EDb3A432268e5831",
    },
    // TON/USD [WETH-USDC]
    "0x15c6eBD4175ffF9EE3c2615c556fCf62D2d9499c": {
      marketTokenAddress: "0x15c6eBD4175ffF9EE3c2615c556fCf62D2d9499c",
      indexTokenAddress: "0xB2f7cefaeEb08Aa347705ac829a7b8bE2FB560f3",
      longTokenAddress: "0x82aF49447D8a07e3bd95BD0d56f35241523fBab1",
      shortTokenAddress: "0xaf88d065e77c8cC2239327C5EDb3A432268e5831",
    },
    // WLD/USD [WETH-USDC]
    "0x872b5D567a2469Ed92D252eaCB0EB3BB0769e05b": {
      marketTokenAddress: "0x872b5D567a2469Ed92D252eaCB0EB3BB0769e05b",
      indexTokenAddress: "0x75B9AdD873641b253718810E6c65dB6d72311FD0",
      longTokenAddress: "0x82aF49447D8a07e3bd95BD0d56f35241523fBab1",
      shortTokenAddress: "0xaf88d065e77c8cC2239327C5EDb3A432268e5831",
    },
    // BONK/USD [WETH-USDC]
    "0xFaC5fF56c269432706d47DC82Ab082E9AE7D989E": {
      marketTokenAddress: "0xFaC5fF56c269432706d47DC82Ab082E9AE7D989E",
      indexTokenAddress: "0x1FD10E767187A92f0AB2ABDEEF4505e319cA06B2",
      longTokenAddress: "0x82aF49447D8a07e3bd95BD0d56f35241523fBab1",
      shortTokenAddress: "0xaf88d065e77c8cC2239327C5EDb3A432268e5831",
    },
    // TAO/USD [WBTC-USDC]
    "0xe55e1A29985488A2c8846a91E925c2B7C6564db1": {
      marketTokenAddress: "0xe55e1A29985488A2c8846a91E925c2B7C6564db1",
      indexTokenAddress: "0x938aef36CAaFbcB37815251B602168087eC14648",
      longTokenAddress: "0x2f2a2543B76A4166549F7aaB2e75Bef0aefC5B0f",
      shortTokenAddress: "0xaf88d065e77c8cC2239327C5EDb3A432268e5831",
    },
    // BOME/USD [WBTC/USDC]
    "0x71237F8C3d1484495A136022E16840b70fF84a69": {
      marketTokenAddress: "0x71237F8C3d1484495A136022E16840b70fF84a69",
      indexTokenAddress: "0x3Eea56A1ccCdbfB70A26aD381C71Ee17E4c8A15F",
      longTokenAddress: "0x2f2a2543B76A4166549F7aaB2e75Bef0aefC5B0f",
      shortTokenAddress: "0xaf88d065e77c8cC2239327C5EDb3A432268e5831",
    },
    // FLOKI/USD [WBTC/USDC]
    "0xfD46a5702D4d97cE0164375744c65F0c31A3901b": {
      marketTokenAddress: "0xfD46a5702D4d97cE0164375744c65F0c31A3901b",
      indexTokenAddress: "0x6792c5B8962ffbDD020c6b6FD0Be7b182e0e33a3",
      longTokenAddress: "0x2f2a2543B76A4166549F7aaB2e75Bef0aefC5B0f",
      shortTokenAddress: "0xaf88d065e77c8cC2239327C5EDb3A432268e5831",
    },
    // MEME/USD [WBTC/USDC]
    "0x6CB901Cc64c024C3Fe4404c940FF9a3Acc229D2C": {
      marketTokenAddress: "0x6CB901Cc64c024C3Fe4404c940FF9a3Acc229D2C",
      indexTokenAddress: "0xaF770F03518686a365300ab35AD860e99967B2f0",
      longTokenAddress: "0x2f2a2543B76A4166549F7aaB2e75Bef0aefC5B0f",
      shortTokenAddress: "0xaf88d065e77c8cC2239327C5EDb3A432268e5831",
    },
    // MEW/USD [WBTC/USDC]
    "0x71B7fF592a974e2B501D8A7a11f5c42DcD365244": {
      marketTokenAddress: "0x71B7fF592a974e2B501D8A7a11f5c42DcD365244",
      indexTokenAddress: "0x5503CF72f54b6d692d36BBCD391516A7dE068687",
      longTokenAddress: "0x2f2a2543B76A4166549F7aaB2e75Bef0aefC5B0f",
      shortTokenAddress: "0xaf88d065e77c8cC2239327C5EDb3A432268e5831",
    },
    // GMX [GMX]
    "0xbD48149673724f9cAeE647bb4e9D9dDaF896Efeb": {
      marketTokenAddress: "0xbD48149673724f9cAeE647bb4e9D9dDaF896Efeb",
      indexTokenAddress: "0xfc5A1A6EB076a2C7aD06eD22C90d7E710E35ad0a",
      longTokenAddress: "0xfc5A1A6EB076a2C7aD06eD22C90d7E710E35ad0a",
      shortTokenAddress: "0xfc5A1A6EB076a2C7aD06eD22C90d7E710E35ad0a",
    },
    // PENDLE/USD [PENDLE/USDC]
    "0x784292E87715d93afD7cb8C941BacaFAAA9A5102": {
      marketTokenAddress: "0x784292E87715d93afD7cb8C941BacaFAAA9A5102",
      indexTokenAddress: "0x0c880f6761F1af8d9Aa9C466984b80DAb9a8c9e8",
      longTokenAddress: "0x0c880f6761F1af8d9Aa9C466984b80DAb9a8c9e8",
      shortTokenAddress: "0xaf88d065e77c8cC2239327C5EDb3A432268e5831",
    },
    // ADA/USD [WBTC/USDC]
    "0xcaCb964144f9056A8f99447a303E60b4873Ca9B4": {
      marketTokenAddress: "0xcaCb964144f9056A8f99447a303E60b4873Ca9B4",
      indexTokenAddress: "0x53186c8419BEB83fE4Da74F7875041a1287337ED",
      longTokenAddress: "0x2f2a2543B76A4166549F7aaB2e75Bef0aefC5B0f",
      shortTokenAddress: "0xaf88d065e77c8cC2239327C5EDb3A432268e5831",
    },
    // BCH/USD [WBTC/USDC]
    "0x62feB8Ec060A7dE5b32BbbF4AC70050f8a043C17": {
      marketTokenAddress: "0x62feB8Ec060A7dE5b32BbbF4AC70050f8a043C17",
      indexTokenAddress: "0xc33D9C096e74aa4f571E9417b69a19C4A1e72ef2",
      longTokenAddress: "0x2f2a2543B76A4166549F7aaB2e75Bef0aefC5B0f",
      shortTokenAddress: "0xaf88d065e77c8cC2239327C5EDb3A432268e5831",
    },
    // DOT/USD [WBTC/USDC]
    "0x7B2D09fca2395713dcc2F67323e4876F27b9ecB2": {
      marketTokenAddress: "0x7B2D09fca2395713dcc2F67323e4876F27b9ecB2",
      indexTokenAddress: "0xE958f107b467d5172573F761d26931D658C1b436",
      longTokenAddress: "0x2f2a2543B76A4166549F7aaB2e75Bef0aefC5B0f",
      shortTokenAddress: "0xaf88d065e77c8cC2239327C5EDb3A432268e5831",
    },
    // ICP/USD [WBTC/USDC]
    "0xdc4e96A251Ff43Eeac710462CD8A9D18Dc802F18": {
      marketTokenAddress: "0xdc4e96A251Ff43Eeac710462CD8A9D18Dc802F18",
      indexTokenAddress: "0xdaf0A71608938F762e37eC5F72F670Cc44703454",
      longTokenAddress: "0x2f2a2543B76A4166549F7aaB2e75Bef0aefC5B0f",
      shortTokenAddress: "0xaf88d065e77c8cC2239327C5EDb3A432268e5831",
    },
    // XLM/USD [WBTC/USDC]
    "0xe902D1526c834D5001575b2d0Ef901dfD0aa097A": {
      marketTokenAddress: "0xe902D1526c834D5001575b2d0Ef901dfD0aa097A",
      indexTokenAddress: "0xc5dbD52Ae5a927Cf585B884011d0C7631C9974c6",
      longTokenAddress: "0x2f2a2543B76A4166549F7aaB2e75Bef0aefC5B0f",
      shortTokenAddress: "0xaf88d065e77c8cC2239327C5EDb3A432268e5831",
    },
    // RENDER/USD [WETH/USDC]
    "0x4c505e0062459cf8F60FfF13279c92ea15aE6e2D": {
      marketTokenAddress: "0x4c505e0062459cf8F60FfF13279c92ea15aE6e2D",
      indexTokenAddress: "0x82BB89fcc64c5d4016C5Ed1AB016bB0D1C20D6C3",
      longTokenAddress: "0x82aF49447D8a07e3bd95BD0d56f35241523fBab1",
      shortTokenAddress: "0xaf88d065e77c8cC2239327C5EDb3A432268e5831",
    },
    // SOL/USD [SOL]
    "0xf22CFFA7B4174554FF9dBf7B5A8c01FaaDceA722": {
      marketTokenAddress: "0xf22CFFA7B4174554FF9dBf7B5A8c01FaaDceA722",
      indexTokenAddress: "0x2bcC6D6CdBbDC0a4071e48bb3B969b06B3330c07",
      longTokenAddress: "0x2bcC6D6CdBbDC0a4071e48bb3B969b06B3330c07",
      shortTokenAddress: "0x2bcC6D6CdBbDC0a4071e48bb3B969b06B3330c07",
    },
    // FIL/USD [WBTC/USDC]
    "0x262B5203f0fe00D9fe86ffecE01D0f54fC116180": {
      marketTokenAddress: "0x262B5203f0fe00D9fe86ffecE01D0f54fC116180",
      indexTokenAddress: "0x3AeBb98f57081DcBEb0B8EA823Cf84900A31e5D8",
      longTokenAddress: "0x2f2a2543B76A4166549F7aaB2e75Bef0aefC5B0f",
      shortTokenAddress: "0xaf88d065e77c8cC2239327C5EDb3A432268e5831",
    },
    // DYDX/USD [WBTC-USDC]
    "0x467C4A46287F6C4918dDF780D4fd7b46419c2291": {
      marketTokenAddress: "0x467C4A46287F6C4918dDF780D4fd7b46419c2291",
      indexTokenAddress: "0x0739Ad7AeA69aD36EdEb91b0e55cAC140427c632",
      longTokenAddress: "0x2f2a2543B76A4166549F7aaB2e75Bef0aefC5B0f",
      shortTokenAddress: "0xaf88d065e77c8cC2239327C5EDb3A432268e5831",
    },
    // INJ/USD [WBTC-USDC]
    "0x16466a03449CB9218EB6A980Aa4a44aaCEd27C25": {
      marketTokenAddress: "0x16466a03449CB9218EB6A980Aa4a44aaCEd27C25",
      indexTokenAddress: "0xfdE73EddbE6c5712A12B72c470F8FE5c77A7fF17",
      longTokenAddress: "0x2f2a2543B76A4166549F7aaB2e75Bef0aefC5B0f",
      shortTokenAddress: "0xaf88d065e77c8cC2239327C5EDb3A432268e5831",
    },
    // TRUMP/USD [ETH-USDC]
    "0xFec8f404FBCa3b11aFD3b3f0c57507C2a06dE636": {
      marketTokenAddress: "0xFec8f404FBCa3b11aFD3b3f0c57507C2a06dE636",
      indexTokenAddress: "0x30021aFA4767Ad66aA52A06dF8a5AB3acA9371fD",
      longTokenAddress: "0x82aF49447D8a07e3bd95BD0d56f35241523fBab1",
      shortTokenAddress: "0xaf88d065e77c8cC2239327C5EDb3A432268e5831",
    },
    // MELANIA/USD [WETH-USDC]
    "0x12fD1A4BdB96219E637180Ff5293409502b2951D": {
      marketTokenAddress: "0x12fD1A4BdB96219E637180Ff5293409502b2951D",
      indexTokenAddress: "0xfa4F8E582214eBCe1A08eB2a65e08082053E441F",
      longTokenAddress: "0x82aF49447D8a07e3bd95BD0d56f35241523fBab1",
      shortTokenAddress: "0xaf88d065e77c8cC2239327C5EDb3A432268e5831",
    },
    // ENA/USD [WETH-USDC]
    "0x9F159014CC218e942E9E9481742fE5BFa9ac5A2C": {
      marketTokenAddress: "0x9F159014CC218e942E9E9481742fE5BFa9ac5A2C",
      indexTokenAddress: "0xfe1Aac2CD9C5cC77b58EeCfE75981866ed0c8b7a",
      longTokenAddress: "0x82aF49447D8a07e3bd95BD0d56f35241523fBab1",
      shortTokenAddress: "0xaf88d065e77c8cC2239327C5EDb3A432268e5831",
    },
    // AI16Z/USD [WBTC.e-USDC]
    "0xD60f1BA6a76979eFfE706BF090372Ebc0A5bF169": {
      marketTokenAddress: "0xD60f1BA6a76979eFfE706BF090372Ebc0A5bF169",
      indexTokenAddress: "0xBb69bd9dc152C2c0F083507641a46193d2B61EBb",
      longTokenAddress: "0x2f2a2543B76A4166549F7aaB2e75Bef0aefC5B0f",
      shortTokenAddress: "0xaf88d065e77c8cC2239327C5EDb3A432268e5831",
    },
    // ANIME/USD [ANIME-USDC]
    "0x5707673D95a8fD317e2745C4217aCD64ca021B68": {
      marketTokenAddress: "0x5707673D95a8fD317e2745C4217aCD64ca021B68",
      indexTokenAddress: "0x37a645648dF29205C6261289983FB04ECD70b4B3",
      longTokenAddress: "0x37a645648dF29205C6261289983FB04ECD70b4B3",
      shortTokenAddress: "0xaf88d065e77c8cC2239327C5EDb3A432268e5831",
    },
    // FARTCOIN/USD [WBTC.e-USDC]
    "0xe2730Ffe2136aA549327EBce93D58160df7821CB": {
      marketTokenAddress: "0xe2730Ffe2136aA549327EBce93D58160df7821CB",
      indexTokenAddress: "0xaca341E61aB6177B0b0Df46a612e4311F8a7605f",
      longTokenAddress: "0x2f2a2543B76A4166549F7aaB2e75Bef0aefC5B0f",
      shortTokenAddress: "0xaf88d065e77c8cC2239327C5EDb3A432268e5831",
    },
  },
  [AVALANCHE]: {
    // BTC/USD [BTC-USDC]
    "0xFb02132333A79C8B5Bd0b64E3AbccA5f7fAf2937": {
      marketTokenAddress: "0xFb02132333A79C8B5Bd0b64E3AbccA5f7fAf2937",
      indexTokenAddress: "0x152b9d0FdC40C096757F570A51E494bd4b943E50",
      longTokenAddress: "0x152b9d0FdC40C096757F570A51E494bd4b943E50",
      shortTokenAddress: "0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E",
    },
    // ETH/USD [ETH-USDC]
    "0xB7e69749E3d2EDd90ea59A4932EFEa2D41E245d7": {
      marketTokenAddress: "0xB7e69749E3d2EDd90ea59A4932EFEa2D41E245d7",
      indexTokenAddress: "0x49D5c2BdFfac6CE2BFdB6640F4F80f226bc10bAB",
      longTokenAddress: "0x49D5c2BdFfac6CE2BFdB6640F4F80f226bc10bAB",
      shortTokenAddress: "0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E",
    },
    // DOGE/USD [WAVAX-USDC]
    "0x8970B527E84aA17a33d38b65e9a5Ab5817FC0027": {
      marketTokenAddress: "0x8970B527E84aA17a33d38b65e9a5Ab5817FC0027",
      indexTokenAddress: "0xC301E6fe31062C557aEE806cc6A841aE989A3ac6",
      longTokenAddress: "0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7",
      shortTokenAddress: "0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E",
    },
    // SOL/USD [SOL-USDC]
    "0xd2eFd1eA687CD78c41ac262B3Bc9B53889ff1F70": {
      marketTokenAddress: "0xd2eFd1eA687CD78c41ac262B3Bc9B53889ff1F70",
      indexTokenAddress: "0xFE6B19286885a4F7F55AdAD09C3Cd1f906D2478F",
      longTokenAddress: "0xFE6B19286885a4F7F55AdAD09C3Cd1f906D2478F",
      shortTokenAddress: "0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E",
    },
    // LTC/USD [WAVAX-USDC]
    "0xA74586743249243D3b77335E15FE768bA8E1Ec5A": {
      marketTokenAddress: "0xA74586743249243D3b77335E15FE768bA8E1Ec5A",
      indexTokenAddress: "0x8E9C35235C38C44b5a53B56A41eaf6dB9a430cD6",
      longTokenAddress: "0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7",
      shortTokenAddress: "0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E",
    },
    // AVAX/USD [WAVAX-USDC]
    "0x913C1F46b48b3eD35E7dc3Cf754d4ae8499F31CF": {
      marketTokenAddress: "0x913C1F46b48b3eD35E7dc3Cf754d4ae8499F31CF",
      indexTokenAddress: "0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7",
      longTokenAddress: "0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7",
      shortTokenAddress: "0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E",
    },
    // SWAP-ONLY [USDC-USDT.e]
    "0xf3652Eba45DC761e7ADd4091627d5Cda21F61613": {
      marketTokenAddress: "0xf3652Eba45DC761e7ADd4091627d5Cda21F61613",
      indexTokenAddress: "0x0000000000000000000000000000000000000000",
      longTokenAddress: "0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E",
      shortTokenAddress: "0xc7198437980c041c805A1EDcbA50c1Ce5db95118",
    },
    // SWAP-ONLY [USDC-USDC.e]
    "0x297e71A931C5825867E8Fb937Ae5cda9891C2E99": {
      marketTokenAddress: "0x297e71A931C5825867E8Fb937Ae5cda9891C2E99",
      indexTokenAddress: "0x0000000000000000000000000000000000000000",
      longTokenAddress: "0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E",
      shortTokenAddress: "0xA7D7079b0FEaD91F3e65f86E8915Cb59c1a4C664",
    },
    // SWAP-ONLY [USDT-USDT.e]
    "0xA7b768d6a1f746fd5a513D440DF2970ff099B0fc": {
      marketTokenAddress: "0xA7b768d6a1f746fd5a513D440DF2970ff099B0fc",
      indexTokenAddress: "0x0000000000000000000000000000000000000000",
      longTokenAddress: "0x9702230A8Ea53601f5cD2dc00fDBc13d4dF4A8c7",
      shortTokenAddress: "0xc7198437980c041c805A1EDcbA50c1Ce5db95118",
    },
    // SWAP-ONLY [USDC-DAI.e]
    "0xDf8c9BD26e7C1A331902758Eb013548B2D22ab3b": {
      marketTokenAddress: "0xDf8c9BD26e7C1A331902758Eb013548B2D22ab3b",
      indexTokenAddress: "0x0000000000000000000000000000000000000000",
      longTokenAddress: "0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E",
      shortTokenAddress: "0xd586E7F844cEa2F87f50152665BCbc2C279D8d70",
    },
    // XRP/USD [WAVAX-USDC]
    "0xD1cf931fa12783c1dd5AbB77a0706c27CF352f25": {
      marketTokenAddress: "0xD1cf931fa12783c1dd5AbB77a0706c27CF352f25",
      indexTokenAddress: "0x34B2885D617cE2ddeD4F60cCB49809fc17bb58Af",
      longTokenAddress: "0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7",
      shortTokenAddress: "0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E",
    },
    // BTC/USD [BTC-BTC]
    "0x3ce7BCDB37Bf587d1C17B930Fa0A7000A0648D12": {
      marketTokenAddress: "0x3ce7BCDB37Bf587d1C17B930Fa0A7000A0648D12",
      indexTokenAddress: "0x152b9d0FdC40C096757F570A51E494bd4b943E50",
      longTokenAddress: "0x152b9d0FdC40C096757F570A51E494bd4b943E50",
      shortTokenAddress: "0x152b9d0FdC40C096757F570A51E494bd4b943E50",
    },
    // ETH/USD [ETH-ETH]
    "0x2A3Cf4ad7db715DF994393e4482D6f1e58a1b533": {
      marketTokenAddress: "0x2A3Cf4ad7db715DF994393e4482D6f1e58a1b533",
      indexTokenAddress: "0x49D5c2BdFfac6CE2BFdB6640F4F80f226bc10bAB",
      longTokenAddress: "0x49D5c2BdFfac6CE2BFdB6640F4F80f226bc10bAB",
      shortTokenAddress: "0x49D5c2BdFfac6CE2BFdB6640F4F80f226bc10bAB",
    },
    // AVAX/USD [AVAX-AVAX]
    "0x08b25A2a89036d298D6dB8A74ace9d1ce6Db15E5": {
      marketTokenAddress: "0x08b25A2a89036d298D6dB8A74ace9d1ce6Db15E5",
      indexTokenAddress: "0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7",
      longTokenAddress: "0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7",
      shortTokenAddress: "0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7",
    },
    // TRUMP/USD [WAVAX-USDC]
    "0xfB626c4e3E153947A6A94041814c25E449064dAD": {
      marketTokenAddress: "0xfB626c4e3E153947A6A94041814c25E449064dAD",
      indexTokenAddress: "0x2f6d7be53fab5538065a226BA091015d422a7528",
      longTokenAddress: "0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7",
      shortTokenAddress: "0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E",
    },
    // MELANIA/USD [WAVAX-USDC]
    "0xe19da27Bf9733c429445E289B662bECDCa6ce10b": {
      marketTokenAddress: "0xe19da27Bf9733c429445E289B662bECDCa6ce10b",
      indexTokenAddress: "0xd42C991a4FAb293C57a7bf25C2E2ec5aE1dB1714",
      longTokenAddress: "0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7",
      shortTokenAddress: "0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E",
    },
  },
  [AVALANCHE_FUJI]: {
    // AVAX/USD [WAVAX-USDC]
    "0xD996ff47A1F763E1e55415BC4437c59292D1F415": {
      marketTokenAddress: "0xD996ff47A1F763E1e55415BC4437c59292D1F415",
      indexTokenAddress: "0x1D308089a2D1Ced3f1Ce36B1FcaF815b07217be3",
      longTokenAddress: "0x1D308089a2D1Ced3f1Ce36B1FcaF815b07217be3",
      shortTokenAddress: "0x3eBDeaA0DB3FfDe96E7a0DBBAFEC961FC50F725F",
    },
    // ETH/USD [ETH-USDC]
    "0xbf338a6C595f06B7Cfff2FA8c958d49201466374": {
      marketTokenAddress: "0xbf338a6C595f06B7Cfff2FA8c958d49201466374",
      indexTokenAddress: "0x82F0b3695Ed2324e55bbD9A9554cB4192EC3a514",
      longTokenAddress: "0x82F0b3695Ed2324e55bbD9A9554cB4192EC3a514",
      shortTokenAddress: "0x3eBDeaA0DB3FfDe96E7a0DBBAFEC961FC50F725F",
    },
    // ETH/USD [ETH-DAI]
    "0xDdF708B284C5C26BE67Adf9C51DFa935b5035bF8": {
      marketTokenAddress: "0xDdF708B284C5C26BE67Adf9C51DFa935b5035bF8",
      indexTokenAddress: "0x82F0b3695Ed2324e55bbD9A9554cB4192EC3a514",
      longTokenAddress: "0x82F0b3695Ed2324e55bbD9A9554cB4192EC3a514",
      shortTokenAddress: "0x51290cb93bE5062A6497f16D9cd3376Adf54F920",
    },
    // ETH/USD [USDC]
    "0xe28323955C05B75E25B56C1c996C1354Eb5Aa13D": {
      marketTokenAddress: "0xe28323955C05B75E25B56C1c996C1354Eb5Aa13D",
      indexTokenAddress: "0x82F0b3695Ed2324e55bbD9A9554cB4192EC3a514",
      longTokenAddress: "0x3eBDeaA0DB3FfDe96E7a0DBBAFEC961FC50F725F",
      shortTokenAddress: "0x3eBDeaA0DB3FfDe96E7a0DBBAFEC961FC50F725F",
    },
    // WBTC/USD [WBTC-USDC]
    "0x79E6e0E454dE82fA98c02dB012a2A69103630B07": {
      marketTokenAddress: "0x79E6e0E454dE82fA98c02dB012a2A69103630B07",
      indexTokenAddress: "0x3Bd8e00c25B12E6E60fc8B6f1E1E2236102073Ca",
      longTokenAddress: "0x3Bd8e00c25B12E6E60fc8B6f1E1E2236102073Ca",
      shortTokenAddress: "0x3eBDeaA0DB3FfDe96E7a0DBBAFEC961FC50F725F",
    },
    // WBTC/USD [WBTC-DAI]
    "0x4b6ccF6E429f038087A26b13DD6ab4304F7E5DF1": {
      marketTokenAddress: "0x4b6ccF6E429f038087A26b13DD6ab4304F7E5DF1",
      indexTokenAddress: "0x3Bd8e00c25B12E6E60fc8B6f1E1E2236102073Ca",
      longTokenAddress: "0x3Bd8e00c25B12E6E60fc8B6f1E1E2236102073Ca",
      shortTokenAddress: "0x51290cb93bE5062A6497f16D9cd3376Adf54F920",
    },
    // SOL/USD [ETH-USDC]
    "0xEDF9Be35bE84cD1e39Bda59Bd7ae8A704C12e06f": {
      marketTokenAddress: "0xEDF9Be35bE84cD1e39Bda59Bd7ae8A704C12e06f",
      indexTokenAddress: "0x137f4a7336df4f3f11894718528516edaaD0B082",
      longTokenAddress: "0x82F0b3695Ed2324e55bbD9A9554cB4192EC3a514",
      shortTokenAddress: "0x3eBDeaA0DB3FfDe96E7a0DBBAFEC961FC50F725F",
    },
    // SWAP-ONLY [USDC-USDT]
    "0xeE8827D67C054cAa89C9d6058Fdddccd1C499c74": {
      marketTokenAddress: "0xeE8827D67C054cAa89C9d6058Fdddccd1C499c74",
      indexTokenAddress: "0x0000000000000000000000000000000000000000",
      longTokenAddress: "0x3eBDeaA0DB3FfDe96E7a0DBBAFEC961FC50F725F",
      shortTokenAddress: "0x50df4892Bd13f01E4e1Cd077ff394A8fa1A3fD7c",
    },
    // DOGE/USD [ETH-DAI]
    "0xAC2c6C1b0cd1CabF78B4e8ad58aA9d43375318Cb": {
      marketTokenAddress: "0xAC2c6C1b0cd1CabF78B4e8ad58aA9d43375318Cb",
      indexTokenAddress: "0x2265F317eA5f47A684E5B26c50948617c945d986",
      longTokenAddress: "0x82F0b3695Ed2324e55bbD9A9554cB4192EC3a514",
      shortTokenAddress: "0x51290cb93bE5062A6497f16D9cd3376Adf54F920",
    },
    // LINK/USD [ETH-DAI]
    "0xeDf53322e288F597436f5d5849771662AEe16A1C": {
      marketTokenAddress: "0xeDf53322e288F597436f5d5849771662AEe16A1C",
      indexTokenAddress: "0x6BD09E8D65AD5cc761DF62454452d4EC1545e647",
      longTokenAddress: "0x82F0b3695Ed2324e55bbD9A9554cB4192EC3a514",
      shortTokenAddress: "0x51290cb93bE5062A6497f16D9cd3376Adf54F920",
    },
    // BNB/USD [ETH-DAI]
    "0x017de90B0fa830C592805C6148c089191716f04c": {
      marketTokenAddress: "0x017de90B0fa830C592805C6148c089191716f04c",
      indexTokenAddress: "0x110892Dd5fa73bE430c0ade694febD9a4CAc68Be",
      longTokenAddress: "0x82F0b3695Ed2324e55bbD9A9554cB4192EC3a514",
      shortTokenAddress: "0x51290cb93bE5062A6497f16D9cd3376Adf54F920",
    },
    // ADA/USD [ETH-DAI]
    "0x695a07d3DD551b0E77A348cC6A873c1eb183FA98": {
      marketTokenAddress: "0x695a07d3DD551b0E77A348cC6A873c1eb183FA98",
      indexTokenAddress: "0xE64dfFF37Fa6Fe969b792B4146cEe2774Ef6e1a1",
      longTokenAddress: "0x82F0b3695Ed2324e55bbD9A9554cB4192EC3a514",
      shortTokenAddress: "0x51290cb93bE5062A6497f16D9cd3376Adf54F920",
    },
    // TRX/USD [ETH-DAI]
    "0x927f31364b8836021e4F73B27a5d0EbB35C74679": {
      marketTokenAddress: "0x927f31364b8836021e4F73B27a5d0EbB35C74679",
      indexTokenAddress: "0x0D1495527C255068F2f6feE31C85d326D0A76FE8",
      longTokenAddress: "0x82F0b3695Ed2324e55bbD9A9554cB4192EC3a514",
      shortTokenAddress: "0x51290cb93bE5062A6497f16D9cd3376Adf54F920",
    },
    // MATIC/USD [ETH-USDC]
    "0x62408de4cB1a499842EC53296EF8dD99A825CcEb": {
      marketTokenAddress: "0x62408de4cB1a499842EC53296EF8dD99A825CcEb",
      indexTokenAddress: "0xadc4698B257F78187Fd675FBf591a09f4c975240",
      longTokenAddress: "0x82F0b3695Ed2324e55bbD9A9554cB4192EC3a514",
      shortTokenAddress: "0x3eBDeaA0DB3FfDe96E7a0DBBAFEC961FC50F725F",
    },
    // DOT/USD [ETH-USDC]
    "0xCc6AC193E1d1Ef102eCBBA864BBfE87E414a7A0D": {
      marketTokenAddress: "0xCc6AC193E1d1Ef102eCBBA864BBfE87E414a7A0D",
      indexTokenAddress: "0x65FFb5664a7B3377A5a27D9e59C72Fb1A5E94962",
      longTokenAddress: "0x82F0b3695Ed2324e55bbD9A9554cB4192EC3a514",
      shortTokenAddress: "0x3eBDeaA0DB3FfDe96E7a0DBBAFEC961FC50F725F",
    },
    // UNI/USD [ETH-USDC]
    "0xE446E8f7074c0A97bb7cd448fA2CC3346045F514": {
      marketTokenAddress: "0xE446E8f7074c0A97bb7cd448fA2CC3346045F514",
      indexTokenAddress: "0xF62dC1d2452d0893735D22945Af53C290b158eAF",
      longTokenAddress: "0x82F0b3695Ed2324e55bbD9A9554cB4192EC3a514",
      shortTokenAddress: "0x3eBDeaA0DB3FfDe96E7a0DBBAFEC961FC50F725F",
    },
    // TEST/USD [ETH-USDC]
    "0x1d9dC405CCEFA78b203BaD9CCe1b1623D2B25D9e": {
      marketTokenAddress: "0x1d9dC405CCEFA78b203BaD9CCe1b1623D2B25D9e",
      indexTokenAddress: "0x42DD131E1086FFCc59bAE9498D71E20E0C889B14",
      longTokenAddress: "0x82F0b3695Ed2324e55bbD9A9554cB4192EC3a514",
      shortTokenAddress: "0x3eBDeaA0DB3FfDe96E7a0DBBAFEC961FC50F725F",
    },
    // WBTC/USD [USDC-USDT]
    "0xd783EB54407d6d3A4D5c94b634eC9BAE3F574098": {
      marketTokenAddress: "0xd783EB54407d6d3A4D5c94b634eC9BAE3F574098",
      indexTokenAddress: "0x3Bd8e00c25B12E6E60fc8B6f1E1E2236102073Ca",
      longTokenAddress: "0x3eBDeaA0DB3FfDe96E7a0DBBAFEC961FC50F725F",
      shortTokenAddress: "0x50df4892Bd13f01E4e1Cd077ff394A8fa1A3fD7c",
    },
    // ETH/USD [USDC-DAI]
    "0x6d72D2787107c32a48bbA4687Eb8F9C19FE5e29C": {
      marketTokenAddress: "0x6d72D2787107c32a48bbA4687Eb8F9C19FE5e29C",
      indexTokenAddress: "0x82F0b3695Ed2324e55bbD9A9554cB4192EC3a514",
      longTokenAddress: "0x3eBDeaA0DB3FfDe96E7a0DBBAFEC961FC50F725F",
      shortTokenAddress: "0x51290cb93bE5062A6497f16D9cd3376Adf54F920",
    },
    // WBTC/USD [WBTC]
    "0x3b649015Fe0a4d15617e57aA11c0FbbfA03A9e11": {
      marketTokenAddress: "0x3b649015Fe0a4d15617e57aA11c0FbbfA03A9e11",
      indexTokenAddress: "0x3Bd8e00c25B12E6E60fc8B6f1E1E2236102073Ca",
      longTokenAddress: "0x3Bd8e00c25B12E6E60fc8B6f1E1E2236102073Ca",
      shortTokenAddress: "0x3Bd8e00c25B12E6E60fc8B6f1E1E2236102073Ca",
    },
  },
};
